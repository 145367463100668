import React from "react";
import { graphql } from "gatsby";
import Layout from "@/components/Layout";
import Seo from "@/components/Seo";
import Header, { NavigationItem } from "@/components/Header";
import Footer, { LinkItem } from "@/components/Footer";
import Link from "@/components/Link";
import Heading from "@/components/Heading";
import { FourOFourPageQuery } from "../../graphql-types";

interface Props {
  data: FourOFourPageQuery;
}

const NotFoundPage = ({ data }: Props) => {
  const { headerLinks, footerLinks } = data.global || {};

  return (
    <Layout scroll="default" header={<Header layout="slim" navigation={headerLinks as NavigationItem[]} />}>
      <Seo title="404 | Oops" />
      <Layout.Section theme="white">
        <Heading>Ooops, you&apos;re lost!</Heading>
        <p>
          Seems like you&apos;ve lost your way! It&apos;s always a good idea to start from{" "}
          <Link className="link" to="/">
            home
          </Link>
          .
        </p>
      </Layout.Section>
      <Footer links={footerLinks as LinkItem[]} />
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query FourOFourPage {
    global: dataJson {
      ...Global
    }
  }
`;
